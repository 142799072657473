var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var SendButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px 50px;\n  background: none;\n  box-shadow: none;\n  border: 2px solid white;\n  color: white;\n  margin-top: 20px;\n  font-family: firabold;\n  font-size: 18px;\n  text-align: center;\n  letter-spacing: 0.15em;\n  text-transform: uppercase;\n  outline: none;\n  cursor: pointer;\n  ", "\n"], ["\n  padding: 10px 50px;\n  background: none;\n  box-shadow: none;\n  border: 2px solid white;\n  color: white;\n  margin-top: 20px;\n  font-family: firabold;\n  font-size: 18px;\n  text-align: center;\n  letter-spacing: 0.15em;\n  text-transform: uppercase;\n  outline: none;\n  cursor: pointer;\n  ",
    "\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n    opacity: 0.6;\n    cursor: default;\n  ";
});
export default SendButton;
var templateObject_1;
