export default {
    ADDRESS: "ბესარიონ ჟღენტის ქუჩა, #16",
    HOW_WORKING: "როგორ ვმუშაობთ",
    ABOUT_US: "ჩვენ შესახებ",
    PARTNERS: "პარტნიორობა",
    CONTACT: "დაგვიკავშირდით",
    FILL_STATEMENT: "შეავსეთ განაცხადი",
    COMPANY_WHO: "ვისთვისაა ჩვენი კომპანია?",
    COMPANY_WHO_DESC: "ჩვენ გვჯერა, რომ მეწარმეები გატაცებულები უნდა იყვნენ საყვარელი საქმით და არ უნდა უწევდეთ იმაზე ფიქრი, სად მოიძიონ ფინანსები საკუთარი ბიზნესისთვის. ძიების პროცესი ხშირად საკმაოდ ხანგრძლივი და დამღლელია, ამიტომ ჩვენ გამოვიგონეთ ახალი, უკეთესი და უფრო სწრაფი გზა, რათა დავეხმაროთ ქართულ კომპანიებს დაფინანსების მიღებაში.",
    SERVICES: "მომსახურება",
    CONSTRUCTION: "მშენებლობა",
    PRODUCTION: "წარმოება",
    TRADE: "ვაჭრობა",
    HOW_WE_DO_TITLE: "ვნახოთ, როგორ ვახერხებთ ამას",
    HOW_WE_DO: "მოცემულია გამყიდველი კომპანია <span>A</span> და შემსყიდველი კომპანია <span2>B</span2>.{br}{br}კომპანია <span2>B</span2>-ს სურს შეისყიდოს ნაწარმი/მომსახურება კომპანია <span>A</span>-სგან კრედიტორული დავალიანებით, მაგრამ კომპანია <span>A</span> უარზეა.{br}{br}როგორც მედიატორები, ჩვენ <tradeshift>(Tradeshift)</tradeshift> ვყიდულობთ პროდუქტს/მომსახურებას კომპანია <span>A</span>-სგან და მივყიდით მას კრედიტორული დავალიანებით კომპანია <span2>B</span2>-ს.{br}{br}კომპანია <span>A</span>-სთან შეთანხმების პროცესში, ჩვენ მას ვაცნობთ ჩვენ სარგებელს და ვეუბნებით, რომ ნებისმიერ დროს, როდესაც კომპანია <span>A</span>-ს დასჭირდება რაიმე პროდუქტის/მომსახურების შეძენა, ჩვენ შეგვიძლია მას ფინანსური მხარდაჭერა გავუწიოთ. ამგვარად, კომპანია <span>A</span>-ს შეუძლია გახდეს კომპანია <span2>B</span2>.",
    ABOUT_PART1: "Tradeshift-ში გვჯერა, რომ ბიზნესის ზრდას დიდი გლობალური სარგებლის მოტანა შეუძლია. სწორედ ამიტომ, პირველივე დღიდან მიზნად დავისახეთ მეწარმეთათვის მათი ბიზნესის დაფინანსება გაგვემარტივებინა.",
    ABOUT_PART2: "ჩვენი სხარტი და მოტივირებული გუნდი გამოირჩევა განსხვავებული აზროვნებით და მის წევრებს არ ეშინიათ გააკეთონ ის, რაც აქამდე არავის გაუბედავს.",
    ABOUT_PART3: "Tradeshift კლიენტებს მომსახურების ფართო სპექტრს ერთ სივრცეში სთავაზობს, მათ შორის - ყოველდღიურ, ყოველკვირეულ ან ყოველთვიურ ვაჭრობას.",
    ABOUT_PART4: "გვწამს, რომ მეწარმეები გატაცებულები უნდა იყვნენ საყვარელი საქმით და არ უნდა უწევდეთ იმაზე ფიქრი, სად მოიძიონ ფინანსები საკუთარი ბიზნესისთვის. ძიების პროცესი ხშირად საკმაოდ ხანგრძლივი და დამღლელია, ამიტომ ჩვენ გამოვიგონეთ ახალი, უკეთესი და უფრო სწრაფი გზა ქართული კომპანიების დასაფინანსებლად. ჩვენი მენეჯმენტის სისტემისა და გუნდის დახმარებით, რომელიც ერთი შეხედვით შეუძლებელსაც კი შესაძლებელს ხდის, ჩვენ ვეხმარებით ბიზნესებს, სწრაფად და მარტივად განკარგონ მათი ფულადი სახსრების დინება.",
    DEVISE: "Tradeshift - “მიეცით თქვენს ბიზნესს სწორი მიმართულება”",
    PARTNER_TITLE: "ჩვენთან თანამშრომლობისას",
    PARTNER_ITEM1: "არ გექნებათ ნაღდი ფულის პრობლემა",
    PARTNER_ITEM2: "არ გექნებათ შიშის ან უნდობლობის გრძნობა",
    PARTNER_ITEM3: "თქვენი ბიზნესი არ შეფერხდება",
    PARTNER_ITEM4: "არ დაკარგავთ ახალ კლიენტებსა და დამატებით შემოსავალს",
    PARTNER_ITEM5: "დაზოგავთ თანხებსა და ენერგიას",
    SEND: "გაგზავნა",
    //REGISTRATION PAGE
    REG_ABOUT_PART1: "Tradeshift-ში გვჯერა, რომ ბიზნესის ზრდას დიდი გლობალური სარგებლის მოტანა შეუძლია. სწორედ ამიტომ, პირველივე დღიდან მიზნად დავისახეთ მეწარმეთათვის მათი ბიზნესპროცესები გაგვემარტივებინა.",
    REG_ABOUT_PART2: "ჩვენი სხარტი და მოტივირებული გუნდი გამოირჩევა განსხვავებული აზროვნებით და მის წევრებს არ ეშინიათ გააკეთონ ის, რაც აქამდე არავის გაუბედავს.",
    REG_ABOUT_PART3: "Tradeshift კლიენტებს მომსახურების ფართო სპექტრს ერთ სივრცეში სთავაზობს, მათ შორის - ყოველდღიურ, ყოველკვირეულ ან ყოველთვიურ ვაჭრობას.",
    REG_PARTNER: "ჩვენთან თანამშრომლობისას",
    REG_PARTNER_PART1: "არ გექნებათ ნაღდი ფულის პრობლემა",
    REG_PARTNER_PART2: "არ გექნებათ შიშის ან უნდობლობის გრძნობა",
    REG_PARTNER_PART3: "თქვენი ბიზნესი არ შეფერხდება",
    REG_PARTNER_PART4: "არ დაკარგავთ ახალ კლიენტებსა და დამატებით შემოსავალს",
    REG_PARTNER_PART5: "დაზოგავთ თანხებსა და ენერგიას",
    START_REGISTRATION: "გაიარეთ რეგისტრაცია",
    PRIVATE_INFO: "შეავსეთ პირადი მონაცემები",
    NAME: "სახელი",
    SURNAME: "გვარი",
    PRIVATE_NUMBER: "პირადი ნომერი",
    MOBILE_NUMBER: "მობილურის ნომერი",
    EMAIL: "ელ-ფოსტა",
    LEGAL_ADDRESS: "იურიდიული მისამართი",
    DOB: "დაბადების თარიღი",
    GENDER: "სქესი",
    JUR_OPTION: "იურიდიული პირის შემთხვევაში",
    JUR_NAME: "იურიდიული დასახელება",
    COMPANY_ID: "საიდენთიფიკაციო კოდი",
    SPHERE_OF_ACTIVITY: "საქმიანობის სფერო",
    JOB_TITLE: "თქვენი თანამდებობა",
    AGREE: "თანხმობა",
    IS_CONSENT: "თანხმობა მონაცემების დამუშავების თაობაზე",
    IDENTIFICATION: "იდენთიფიკაცია",
    RULE_TEXT_PART1: "იმისათვის რომ მიიღოთ პირველადი პასუხი ფილიალში მოსვლის გარეშე, პირადი მონაცემების იდენთიფიკაციისთვის გთხოვთ თქვენი ინტენეტ ბანკით გადმორიცხოთ 1 თეთრი ანგარიშზე",
    RULE_TEXT_PART2: "იდენთიფიკაციის შემდგომ ჩვენი ბიზნეს კონსულტანტი დაგიკავშირდებათ განაცხადში მითითებულ ნომერზე",
    ACCOUNT_NUMBER: "ანგარიშის ნომერი",
    FORM_SEND: "განაცხადის გაგზავნა",
    //FEEDBACK
    NAME_AND_SURNAME: "სახელი და გვარი",
    MESSAGE: "თქვენი შეტყობინება",
    REQUIRED: "ველი სავალდებულოა",
    EMAIL_FORMAT: "ელ-ფოსტის ფორმატი არასწორია.",
    SELECT: "აირჩიეთ",
    MALE: "მამრობითი",
    FEMALE: "მდედრობითი"
};
