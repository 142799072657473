var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import device from "./../config/device";
import setting, { asset } from "../utils/setting";
import uniqid from "../utils/uniqid";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import swal from "@sweetalert/with-react";
import messages from "../locales";
import RegistrationInfo from "./../components/RegistrationInfo";
import { LanguageContext } from "../contexts/LanguageContext";
var dateIcon = asset("img/date.svg");
var SingUp = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: stretch;\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n  @media ", " {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: stretch;\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n  @media ", " {\n    flex-direction: column;\n  }\n"])), device.lg);
var DateIcon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 30px;\n  height: 30px;\n  margin-right: auto;\n  margin-left: 15px;\n  flex-shrink: 0;\n  background: url(", ") no-repeat center;\n  background-size: contain;\n"], ["\n  width: 30px;\n  height: 30px;\n  margin-right: auto;\n  margin-left: 15px;\n  flex-shrink: 0;\n  background: url(", ") no-repeat center;\n  background-size: contain;\n"])), dateIcon);
var Inline = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n"])));
var StyledForm = styled.form(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  flex: 0 1 auto;\n  padding: 50px;\n  @media ", " {\n    padding: 25px;\n  }\n"], ["\n  width: 100%;\n  flex: 0 1 auto;\n  padding: 50px;\n  @media ", " {\n    padding: 25px;\n  }\n"])), device.xl);
var Input = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 44px;\n  font: 17px firareg;\n  color: #000;\n  border: ", ";\n  border-radius: 5px;\n  outline: none;\n  padding: 0 10px;\n  max-width: ", ";\n  @media ", " {\n    max-width: 100%;\n  }\n"], ["\n  width: 100%;\n  height: 44px;\n  font: 17px firareg;\n  color: #000;\n  border: ", ";\n  border-radius: 5px;\n  outline: none;\n  padding: 0 10px;\n  max-width: ", ";\n  @media ", " {\n    max-width: 100%;\n  }\n"])), function (props) { return props.border || "2px solid #257ABE"; }, function (props) { return props.maxWidth || "230px"; }, device.sm);
var Select = styled.select(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  height: 44px;\n  font: 17px firareg;\n  color: #000;\n  border: ", ";\n  border-radius: 5px;\n  outline: none;\n  padding: 0 10px;\n  overflow: hidden;\n  max-width: ", ";\n  @media ", " {\n    max-width: 100%;\n  }\n"], ["\n  width: 100%;\n  height: 44px;\n  font: 17px firareg;\n  color: #000;\n  border: ", ";\n  border-radius: 5px;\n  outline: none;\n  padding: 0 10px;\n  overflow: hidden;\n  max-width: ", ";\n  @media ", " {\n    max-width: 100%;\n  }\n"])), function (props) { return props.border || "2px solid #257ABE"; }, function (props) { return props.maxWidth || "230px"; }, device.sm);
var Button = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #257abe;\n  border-radius: 5px;\n  outline: none;\n  border: none;\n  cursor: pointer;\n  padding: 0 15px;\n  height: 38px;\n  font-size: 14px;\n  font-family: firabold;\n  color: #fff;\n  white-space: nowrap;\n  @media ", " {\n    margin-top: 20px;\n    width: 100%;\n    height: 50px;\n  }\n  ", "\n"], ["\n  background: #257abe;\n  border-radius: 5px;\n  outline: none;\n  border: none;\n  cursor: pointer;\n  padding: 0 15px;\n  height: 38px;\n  font-size: 14px;\n  font-family: firabold;\n  color: #fff;\n  white-space: nowrap;\n  @media ", " {\n    margin-top: 20px;\n    width: 100%;\n    height: 50px;\n  }\n  ",
    "\n"])), device.sm, function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n    opacity: 0.6;\n    cursor: default;\n  ";
});
var InputWrp = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-width: 230px;\n  margin-bottom: 15px;\n  input,\n  select {\n    margin-top: 5px;\n  }\n  @media ", " {\n    max-width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-width: 230px;\n  margin-bottom: 15px;\n  input,\n  select {\n    margin-top: 5px;\n  }\n  @media ", " {\n    max-width: 100%;\n  }\n"])), device.sm);
var Label = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font: 13px firabold;\n  color: #61636b;\n  span {\n    color: #ec1e24;\n  }\n"], ["\n  font: 13px firabold;\n  color: #61636b;\n  span {\n    color: #ec1e24;\n  }\n"])));
var LabelBig = styled(Label)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 16px;\n  margin-bottom: 25px;\n  display: block;\n  font-family: firareg;\n  input {\n    margin-right: 8px;\n    width: 18px;\n    height: 18px;\n    border-radius: 2px;\n    color: #257abe;\n  }\n"], ["\n  font-size: 16px;\n  margin-bottom: 25px;\n  display: block;\n  font-family: firareg;\n  input {\n    margin-right: 8px;\n    width: 18px;\n    height: 18px;\n    border-radius: 2px;\n    color: #257abe;\n  }\n"])));
var Title = styled.h1(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font: 26px firabold;\n  color: #246bb4;\n  margin-bottom: 25px;\n"], ["\n  font: 26px firabold;\n  color: #246bb4;\n  margin-bottom: 25px;\n"])));
var Window = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin: 15px 0;\n"], ["\n  margin: 15px 0;\n"])));
var Board = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: 0 15px;\n  width: 100%;\n"], ["\n  padding: 0 15px;\n  width: 100%;\n"])));
var InputBoard = styled(Board)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  max-width: 550px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-bottom: 20px;\n"], ["\n  max-width: 550px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-bottom: 20px;\n"])));
var Text = styled.p(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font: 16px firareg;\n  color: #61636b;\n  margin-bottom: 20px;\n"], ["\n  font: 16px firareg;\n  color: #61636b;\n  margin-bottom: 20px;\n"])));
var Billing = styled.p(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font: 16px firareg;\n  color: #246bb4;\n  margin-bottom: 20px;\n"], ["\n  font: 16px firareg;\n  color: #246bb4;\n  margin-bottom: 20px;\n"])));
var TitleSecondary = styled.h1(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  font: 18px firabold;\n  color: #246bb4;\n  margin-bottom: 25px;\n  display: flex;\n  align-items: center;\n  input {\n    margin-right: 8px;\n    width: 18px;\n    height: 18px;\n    border-radius: 2px;\n    color: #257abe;\n    margin-left: 10px;\n  }\n"], ["\n  font: 18px firabold;\n  color: #246bb4;\n  margin-bottom: 25px;\n  display: flex;\n  align-items: center;\n  input {\n    margin-right: 8px;\n    width: 18px;\n    height: 18px;\n    border-radius: 2px;\n    color: #257abe;\n    margin-left: 10px;\n  }\n"])));
var Action = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 50px 0;\n  @media ", " {\n    flex-direction: column;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 50px 0;\n  @media ", " {\n    flex-direction: column;\n  }\n"])), device.sm);
var ErrorMessage = styled.span(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  color: #dc3545;\n  font-family: \"firareg\";\n  font-size: 0.85rem;\n  margin-top: 5px;\n"], ["\n  color: #dc3545;\n  font-family: \"firareg\";\n  font-size: 0.85rem;\n  margin-top: 5px;\n"])));
var Registration = function () {
    var _a;
    var executeRecaptcha = useGoogleReCaptcha().executeRecaptcha;
    var language = useContext(LanguageContext).language;
    var intl = useIntl();
    var VALIDATION_SCHEMA = Yup.object().shape({
        // Personal details
        //@ts-ignore
        first_name: Yup.string().required(messages[language]["REQUIRED"]),
        //@ts-ignore
        last_name: Yup.string().required(messages[language]["REQUIRED"]),
        //@ts-ignore
        personal_id: Yup.string().required(messages[language]["REQUIRED"]),
        //@ts-ignore
        mobile_number: Yup.string().required(messages[language]["REQUIRED"]),
        email: Yup.string()
            //@ts-ignore
            .required(messages[language]["REQUIRED"])
            //@ts-ignore
            .email(messages[language]["EMAIL_FORMAT"]),
        //@ts-ignore
        legal_address: Yup.string().required(messages[language]["REQUIRED"]),
        //@ts-ignore
        dob: Yup.string().required(messages[language]["REQUIRED"]),
        gender: Yup.string()
            //@ts-ignore
            .required(messages[language]["REQUIRED"])
            //@ts-ignore
            .oneOf(["0", "1"], messages[language]["REQUIRED"]),
        // Company details
        company_legal_name: Yup.string().when("is_company", {
            is: true,
            //@ts-ignore
            then: Yup.string().required(messages[language]["REQUIRED"]),
            otherwise: Yup.string()
        }),
        company_id: Yup.string().when("is_company", {
            is: true,
            //@ts-ignore
            then: Yup.string().required(messages[language]["REQUIRED"]),
            otherwise: Yup.string()
        }),
        sphere_of_activity: Yup.string().when("is_company", {
            is: true,
            //@ts-ignore
            then: Yup.string().required(messages[language]["REQUIRED"]),
            otherwise: Yup.string()
        }),
        job_title: Yup.string().when("is_company", {
            is: true,
            //@ts-ignore
            then: Yup.string().required(messages[language]["REQUIRED"]),
            otherwise: Yup.string()
        }),
        // Is Consent
        is_consent: Yup.boolean()
            //@ts-ignore
            .equals([true], messages[language]["REQUIRED"])
            //@ts-ignore
            .required(messages[language]["REQUIRED"])
    });
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var gRecaptchaResponse, headers, url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, executeRecaptcha(setting("submission_form__recaptcha_action"))];
                case 1:
                    gRecaptchaResponse = _a.sent();
                    values = __assign(__assign({}, values), { locale: language });
                    headers = new Headers();
                    headers.set(setting("recaptcha__header"), gRecaptchaResponse);
                    headers.set("Content-Type", "application/json");
                    headers.set("X-Requested-With", "XMLHttpRequest");
                    url = setting("api__submissions__create_url");
                    return [4 /*yield*/, fetch(url, {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(values)
                        })];
                case 2:
                    response = _a.sent();
                    if (response.ok) {
                        swal("მადლობა", "თქვენი განაცხადი მიღებულია", "success");
                        formik.resetForm();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var formik = useFormik({
        initialValues: {
            // Personal details
            first_name: "",
            last_name: "",
            personal_id: "",
            mobile_number: "",
            email: "",
            legal_address: "",
            dob: "",
            gender: "",
            // Company details
            company_legal_name: "",
            company_id: "",
            sphere_of_activity: "",
            job_title: "",
            // Other values
            is_company: "",
            is_consent: ""
        },
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validationSchema: VALIDATION_SCHEMA
    });
    return (React.createElement(SingUp, null,
        React.createElement(RegistrationInfo, null),
        React.createElement(StyledForm, { onSubmit: formik.handleSubmit },
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { id: "START_REGISTRATION" })),
            React.createElement(TitleSecondary, null,
                React.createElement(FormattedMessage, { id: "PRIVATE_INFO" })),
            React.createElement(InputBoard, null,
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("first_name") },
                        React.createElement(FormattedMessage, { id: "NAME" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "first_name", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.first_name }),
                    formik.touched.first_name && (React.createElement(ErrorMessage, null, formik.errors.first_name))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("last_name") },
                        React.createElement(FormattedMessage, { id: "SURNAME" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "last_name", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.last_name }),
                    formik.touched.last_name && (React.createElement(ErrorMessage, null, formik.errors.last_name))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("personal_id") },
                        React.createElement(FormattedMessage, { id: "PRIVATE_NUMBER" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "personal_id", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.personal_id }),
                    formik.touched.personal_id && (React.createElement(ErrorMessage, null, formik.errors.personal_id))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("mobile_number") },
                        React.createElement(FormattedMessage, { id: "MOBILE_NUMBER" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "mobile_number", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.mobile_number }),
                    formik.touched.mobile_number && (React.createElement(ErrorMessage, null, formik.errors.mobile_number))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("email") },
                        React.createElement(FormattedMessage, { id: "EMAIL" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "email", name: "email", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.email }),
                    formik.touched.email && (React.createElement(ErrorMessage, null, formik.errors.email))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("legal_address") },
                        React.createElement(FormattedMessage, { id: "LEGAL_ADDRESS" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "legal_address", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.legal_address }),
                    formik.touched.legal_address && (React.createElement(ErrorMessage, null, formik.errors.legal_address))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("dob") },
                        React.createElement(FormattedMessage, { id: "DOB" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Inline, null,
                        React.createElement(Input, { id: uniqid.last(), maxWidth: "190px", type: "date", name: "dob", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.dob }),
                        React.createElement(DateIcon, null)),
                    formik.touched.dob && (React.createElement(ErrorMessage, null, formik.errors.dob))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("gender") },
                        React.createElement(FormattedMessage, { id: "GENDER" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Select, { id: uniqid.last(), maxWidth: "190px", name: "gender", defaultValue: formik.values.gender, onChange: formik.handleChange, onBlur: formik.handleBlur, style: { display: "block" } },
                        React.createElement("option", { value: "", disabled: true }, intl.formatMessage({ id: "SELECT" })),
                        React.createElement("option", { value: "0" }, intl.formatMessage({ id: "MALE" })),
                        React.createElement("option", { value: "1" }, intl.formatMessage({ id: "FEMALE" }))),
                    formik.touched.gender && (React.createElement(ErrorMessage, null, formik.errors.gender)))),
            React.createElement(TitleSecondary, null,
                React.createElement(FormattedMessage, { id: "JUR_OPTION" }),
                " ",
                React.createElement("input", { type: "checkbox", name: "is_company", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.is_company })),
            formik.values.is_company && (React.createElement(InputBoard, null,
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("company_legal_name") },
                        React.createElement(FormattedMessage, { id: "JUR_NAME" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "company_legal_name", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.company_legal_name }),
                    formik.touched.company_legal_name && (React.createElement(ErrorMessage, null, formik.errors.company_legal_name))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("company_id") },
                        React.createElement(FormattedMessage, { id: "COMPANY_ID" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "company_id", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.company_id }),
                    formik.touched.company_id && (React.createElement(ErrorMessage, null, formik.errors.company_id))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("sphere_of_activity") },
                        React.createElement(FormattedMessage, { id: "SPHERE_OF_ACTIVITY" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "sphere_of_activity", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.sphere_of_activity }),
                    formik.touched.sphere_of_activity && (React.createElement(ErrorMessage, null, formik.errors.sphere_of_activity))),
                React.createElement(InputWrp, null,
                    React.createElement(Label, { htmlFor: uniqid("job_title") },
                        React.createElement(FormattedMessage, { id: "JOB_TITLE" }),
                        React.createElement("span", null, "*")),
                    React.createElement(Input, { id: uniqid.last(), type: "text", name: "job_title", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.job_title }),
                    formik.touched.job_title && (React.createElement(ErrorMessage, null, formik.errors.job_title))))),
            React.createElement(Window, null,
                React.createElement(TitleSecondary, null,
                    React.createElement(FormattedMessage, { id: "AGREE" })),
                React.createElement(Board, null, (_a = setting(language === "ka"
                    ? "submission_form__terms_and_conditions_ka"
                    : "submission_form__terms_and_conditions_en") // TODO: use correct locale
                ) === null || _a === void 0 ? void 0 :
                    _a.split("\n\n").map(function (text, index) { return (React.createElement(Text, { key: index }, text)); }),
                    React.createElement(LabelBig, null,
                        React.createElement("input", { type: "checkbox", name: "is_consent", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.is_consent }),
                        React.createElement(FormattedMessage, { id: "IS_CONSENT" }),
                        React.createElement("span", null, "*")))),
            React.createElement(Window, null,
                React.createElement(TitleSecondary, null,
                    React.createElement(FormattedMessage, { id: "IDENTIFICATION" })),
                React.createElement(Board, null,
                    React.createElement(Text, null,
                        React.createElement(FormattedMessage, { id: "RULE_TEXT_PART1" })),
                    React.createElement(Billing, null,
                        React.createElement(FormattedMessage, { id: "ACCOUNT_NUMBER" }),
                        " -",
                        " ",
                        setting("submission_form__bank_account_number")),
                    React.createElement(Text, null,
                        React.createElement(FormattedMessage, { id: "RULE_TEXT_PART2" })))),
            React.createElement(Action, null,
                React.createElement(Button, { type: "submit", disabled: !formik.isValid || formik.isSubmitting },
                    React.createElement(FormattedMessage, { id: "FORM_SEND" }),
                    " ")))));
};
export default Registration;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
