import React, { useContext, Fragment } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import messages from "./locales";
import GlobalStyle, { CssReset } from "./components/GlobalStyle";
import MainPage from "./pages/Main";
import RegistrationPage from "./pages/Registration";
import setting from "./utils/setting";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./components/ScrollToTop";
import { LanguageContext } from "./contexts/LanguageContext";
var App = function () {
    var language = useContext(LanguageContext).language;
    return (React.createElement(Fragment, null,
        React.createElement(IntlProvider, { locale: language, messages: messages[language] },
            React.createElement(GoogleReCaptchaProvider, { reCaptchaKey: setting("recaptcha__site_key"), language: setting("app__locale") },
                React.createElement(CssReset, null),
                React.createElement(GlobalStyle, null),
                React.createElement(Router, null,
                    React.createElement(ScrollToTop, null),
                    React.createElement(Route, { path: "/register" },
                        React.createElement(RegistrationPage, null)),
                    React.createElement(Route, { exact: true, path: "/" },
                        React.createElement(MainPage, null)))))));
};
export default App;
