var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { asset } from "../utils/setting";
import Logo from "./../components/Logo";
import device from "./../config/device";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
var regBG = asset("img/reg-bg.png");
var logoIcon = asset("img/logo-dark.png");
var earthIcon = asset("img/earth.svg");
var locationIcon = asset("img/location.svg");
var emailIcon = asset("img/mail.svg");
var phoneIcon = asset("img/phone.svg");
var About = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  flex: 0 1 auto;\n  display: flex;\n  flex-direction: column;\n  background-color: #fafafa;\n  @media ", " {\n    width: 55%;\n  }\n  @media ", " {\n    width: 100%;\n  }\n"], ["\n  width: 100%;\n  flex: 0 1 auto;\n  display: flex;\n  flex-direction: column;\n  background-color: #fafafa;\n  @media ", " {\n    width: 55%;\n  }\n  @media ", " {\n    width: 100%;\n  }\n"])), device.xl, device.lg);
var AboutText = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font: 14px firareg;\n  color: #61636b;\n  margin-bottom: 15px;\n  line-height: 20px;\n  &:nth-child(2) {\n    margin-top: 25px;\n  }\n"], ["\n  font: 14px firareg;\n  color: #61636b;\n  margin-bottom: 15px;\n  line-height: 20px;\n  &:nth-child(2) {\n    margin-top: 25px;\n  }\n"])));
var AboutInfo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 50px;\n  width: 100%;\n  background: url(", ") no-repeat center center;\n  background-size: contain;\n  flex: 1 0 auto;\n  @media ", " {\n    padding: 25px;\n  }\n  @media ", " {\n    background-size: 100%;\n  }\n"], ["\n  padding: 50px;\n  width: 100%;\n  background: url(", ") no-repeat center center;\n  background-size: contain;\n  flex: 1 0 auto;\n  @media ", " {\n    padding: 25px;\n  }\n  @media ", " {\n    background-size: 100%;\n  }\n"])), regBG, device.xl, device.lg);
var AboutList = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 60px;\n  @media ", " {\n    margin-top: 100px;\n    padding-left: 50px;\n  }\n"], ["\n  width: 100%;\n  margin-top: 60px;\n  @media ", " {\n    margin-top: 100px;\n    padding-left: 50px;\n  }\n"])), device.lg);
var AboutListHd = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font: 16px firabold;\n  color: #246bb4;\n  padding-left: 10px;\n  text-transform: uppercase;\n"], ["\n  font: 16px firabold;\n  color: #246bb4;\n  padding-left: 10px;\n  text-transform: uppercase;\n"])));
var AboutItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font: 13px firabold;\n  color: #61636b;\n  padding-left: 15px;\n  margin-top: 15px;\n  position: relative;\n  &:before {\n    position: absolute;\n    top: 50%;\n    transform: translate(0, -50%);\n    left: 0;\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background: #246bb4;\n    content: \"\";\n  }\n"], ["\n  font: 13px firabold;\n  color: #61636b;\n  padding-left: 15px;\n  margin-top: 15px;\n  position: relative;\n  &:before {\n    position: absolute;\n    top: 50%;\n    transform: translate(0, -50%);\n    left: 0;\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background: #246bb4;\n    content: \"\";\n  }\n"])));
var Footer = styled.footer(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  background: #257abe;\n  padding: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  @media ", " {\n    flex-wrap: wrap;\n  }\n  @media ", " {\n    flex-wrap: nowrap;\n    margin-top: 40px;\n  }\n"], ["\n  width: 100%;\n  background: #257abe;\n  padding: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  @media ", " {\n    flex-wrap: wrap;\n  }\n  @media ", " {\n    flex-wrap: nowrap;\n    margin-top: 40px;\n  }\n"])), device.xl, device.lg);
var FooterWrapper = styled.footer(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-width: 600px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n"], ["\n  max-width: 600px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n"])));
var FooterItem = styled.footer(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 50%;\n  font: 13px firareg;\n  color: #fff;\n  margin: 20px 0;\n  @media ", " {\n    width: 100%;\n  }\n  @media ", " {\n    width: 50%;\n  }\n"], ["\n  width: 50%;\n  font: 13px firareg;\n  color: #fff;\n  margin: 20px 0;\n  @media ", " {\n    width: 100%;\n  }\n  @media ", " {\n    width: 50%;\n  }\n"])), device.xl, device.lg);
var FooterIcon = styled.img(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-right: 15px;\n  width: 15px;\n  height: 15px;\n  object-fit: contain;\n  object-position: center;\n"], ["\n  margin-right: 15px;\n  width: 15px;\n  height: 15px;\n  object-fit: contain;\n  object-position: center;\n"])));
var RegistrationInfo = function () { return (React.createElement(About, null,
    React.createElement(AboutInfo, null,
        React.createElement(Link, { to: "/" },
            React.createElement(Logo, { icon: logoIcon })),
        React.createElement(AboutText, null,
            React.createElement(FormattedMessage, { id: "REG_ABOUT_PART1" })),
        React.createElement(AboutText, null,
            React.createElement(FormattedMessage, { id: "REG_ABOUT_PART2" })),
        React.createElement(AboutText, null,
            React.createElement(FormattedMessage, { id: "REG_ABOUT_PART3" })),
        React.createElement(AboutList, null,
            React.createElement(AboutListHd, null,
                React.createElement(FormattedMessage, { id: "REG_PARTNER" })),
            React.createElement(AboutItem, null,
                React.createElement(FormattedMessage, { id: "REG_PARTNER_PART1" })),
            React.createElement(AboutItem, null,
                React.createElement(FormattedMessage, { id: "REG_PARTNER_PART2" })),
            React.createElement(AboutItem, null,
                React.createElement(FormattedMessage, { id: "REG_PARTNER_PART3" })),
            React.createElement(AboutItem, null,
                React.createElement(FormattedMessage, { id: "REG_PARTNER_PART4" })),
            React.createElement(AboutItem, null,
                React.createElement(FormattedMessage, { id: "REG_PARTNER_PART5" })))),
    React.createElement(Footer, null,
        React.createElement(FooterWrapper, null,
            React.createElement(FooterItem, null,
                React.createElement(FooterIcon, { src: earthIcon }),
                " www.trade-shift.com"),
            React.createElement(FooterItem, null,
                React.createElement(FooterIcon, { src: locationIcon }),
                " ",
                React.createElement(FormattedMessage, { id: "ADDRESS" })),
            React.createElement(FooterItem, null,
                React.createElement(FooterIcon, { src: emailIcon }),
                " hello@tradeshift.ge"),
            React.createElement(FooterItem, null,
                React.createElement(FooterIcon, { src: phoneIcon }),
                " 0322 194 494"))))); };
export default RegistrationInfo;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
