var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import * as Yup from "yup";
import swal from "@sweetalert/with-react";
import Wrapper from "./../components/Wrapper";
import SendButton from "./../components/SendButton";
import setting, { asset } from "../utils/setting";
import device from "../config/device";
import { useFormik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LanguageContext } from "../contexts/LanguageContext";
var feedbackBG = asset("img/footer.jpg");
var WrapperExt = styled(Wrapper)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: stretch;\n  @media ", " {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: stretch;\n  @media ", " {\n    flex-direction: column;\n  }\n"])), device.lg);
var StyledFeedback = styled.form(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  background: url(", ") no-repeat;\n  background-size: cover;\n  padding: 65px 0;\n"], ["\n  width: 100%;\n  background: url(", ") no-repeat;\n  background-size: cover;\n  padding: 65px 0;\n"])), feedbackBG);
var Map = styled.iframe(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0 15px;\n  border: none;\n  @media ", " {\n    height: 300px;\n    margin-bottom: 50px;\n  }\n"], ["\n  width: 100%;\n  padding: 0 15px;\n  border: none;\n  @media ", " {\n    height: 300px;\n    margin-bottom: 50px;\n  }\n"])), device.lg);
var FeedbackForm = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0 15px;\n"], ["\n  width: 100%;\n  padding: 0 15px;\n"])));
var FeedbackInput = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  border: none;\n  outline: none;\n  box-shadow: none;\n  border-bottom: 2px solid white;\n  background: transparent;\n  border-radius: 0;\n  margin-bottom: 40px;\n  font-family: firareg;\n  font-size: 18px;\n  letter-spacing: 0.15em;\n  color: #ffffff;\n  padding: 6px 12px;\n  &::placeholder {\n    color: #ffffff;\n  }\n\n  ", "\n"], ["\n  width: 100%;\n  border: none;\n  outline: none;\n  box-shadow: none;\n  border-bottom: 2px solid white;\n  background: transparent;\n  border-radius: 0;\n  margin-bottom: 40px;\n  font-family: firareg;\n  font-size: 18px;\n  letter-spacing: 0.15em;\n  color: #ffffff;\n  padding: 6px 12px;\n  &::placeholder {\n    color: #ffffff;\n  }\n\n  ", "\n"])), function (_a) {
    var hasError = _a.hasError;
    return hasError && "border-bottom-color: #dc3545;";
});
var FeedbackTextarea = styled.textarea(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  border: none;\n  outline: none;\n  box-shadow: none;\n  border-bottom: 2px solid white;\n  background: transparent;\n  border-radius: 0;\n  margin-bottom: 40px;\n  font-family: firareg;\n  font-size: 18px;\n  letter-spacing: 0.15em;\n  color: #ffffff;\n  padding: 6px 12px;\n  &::placeholder {\n    color: #ffffff;\n  }\n\n  ", "\n"], ["\n  width: 100%;\n  border: none;\n  outline: none;\n  box-shadow: none;\n  border-bottom: 2px solid white;\n  background: transparent;\n  border-radius: 0;\n  margin-bottom: 40px;\n  font-family: firareg;\n  font-size: 18px;\n  letter-spacing: 0.15em;\n  color: #ffffff;\n  padding: 6px 12px;\n  &::placeholder {\n    color: #ffffff;\n  }\n\n  ", "\n"])), function (_a) {
    var hasError = _a.hasError;
    return hasError && "border-bottom-color: #dc3545;";
});
var VALIDATION_SCHEMA = Yup.object().shape({
    full_name: Yup.string().required(),
    email: Yup.string()
        .required()
        .email(),
    message: Yup.string().required()
});
var Feedback = function () {
    var language = useContext(LanguageContext).language;
    var executeRecaptcha = useGoogleReCaptcha().executeRecaptcha;
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var gRecaptchaResponse, headers, url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, executeRecaptcha(setting("contact_form__recaptcha_action"))];
                case 1:
                    gRecaptchaResponse = _a.sent();
                    headers = new Headers();
                    headers.set(setting("recaptcha__header"), gRecaptchaResponse);
                    headers.set("Content-Type", "application/json");
                    headers.set("X-Requested-With", "XMLHttpRequest");
                    url = setting("api__contact_form__submit_url");
                    return [4 /*yield*/, fetch(url, {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(values)
                        })];
                case 2:
                    response = _a.sent();
                    if (response.ok) {
                        swal("მადლობა", "თქვენი შეტყობინება მიღებულია", "success");
                        formik.resetForm();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var formik = useFormik({
        initialValues: {
            full_name: "",
            email: "",
            message: ""
        },
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validationSchema: VALIDATION_SCHEMA
    });
    return (React.createElement(StyledFeedback, { onSubmit: formik.handleSubmit },
        React.createElement(WrapperExt, null,
            React.createElement(Map, { src: language === "ka"
                    ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2432.533695817144!2d44.725308475899475!3d41.730217824122626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044731aea6aaaab%3A0xffaae02d078f991c!2sTradeshift!5e0!3m2!1ska!2sge!4v1607963294399!5m2!1ska!2sge"
                    : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2432.533695817144!2d44.725308475899475!3d41.730217824122626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044731aea6aaaab%3A0xffaae02d078f991c!2sTradeshift!5e0!3m2!1sen!2sge!4v1607963294399!5m2!1sen!2sge" }),
            React.createElement(FeedbackForm, null,
                React.createElement(FormattedMessage, { id: "NAME_AND_SURNAME" }, function (msg) { return (React.createElement(FeedbackInput, { name: "full_name", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.full_name, hasError: formik.touched.full_name && !!formik.errors.full_name, type: "text", placeholder: msg })); }),
                React.createElement(FormattedMessage, { id: "EMAIL" }, function (msg) { return (React.createElement(FeedbackInput, { name: "email", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.email, hasError: formik.touched.email && !!formik.errors.email, type: "email", placeholder: msg })); }),
                React.createElement(FormattedMessage, { id: "MESSAGE" }, function (msg) { return (React.createElement(FeedbackTextarea, { cols: 30, rows: 7, name: "message", onChange: formik.handleChange, onBlur: formik.handleBlur, value: formik.values.message, hasError: formik.touched.message && !!formik.errors.message, placeholder: msg })); }),
                React.createElement(SendButton, { type: "submit", disabled: !formik.isValid || formik.isSubmitting },
                    React.createElement(FormattedMessage, { id: "SEND" }))))));
};
export default Feedback;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
