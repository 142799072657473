var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Fragment } from "react";
import styled from "styled-components";
import { asset } from "./../utils/setting";
import device from "./../config/device";
import { FormattedMessage } from "react-intl";
import Header from "./../components/Header";
import Wrapper from "./../components/Wrapper";
import Feedback from "../components/Feedback";
var companyBG = asset("img/bg-header.jpg");
var OffersIcon1 = asset("img/svg-1.svg");
var OffersIcon2 = asset("img/svg-2.svg");
var OffersIcon3 = asset("img/svg-3.svg");
var OffersIcon4 = asset("img/svg-4.svg");
var BgPart = asset("img/cloud.svg");
var partnerIcon = asset("img/partner.svg");
var OurCompany = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 500px;\n  background: url(", ") no-repeat center;\n  background-size: cover;\n  padding-top: 150px;\n  @media ", " {\n    height: auto;\n    padding-bottom: 100px;\n  }\n"], ["\n  width: 100%;\n  height: 500px;\n  background: url(", ") no-repeat center;\n  background-size: cover;\n  padding-top: 150px;\n  @media ", " {\n    height: auto;\n    padding-bottom: 100px;\n  }\n"])), companyBG, device.xl);
var OurCompanyTitle = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 48px;\n  letter-spacing: 3px;\n  color: #fff;\n  font-family: firabold;\n  @media ", " {\n    font-size: 35px;\n  }\n"], ["\n  font-size: 48px;\n  letter-spacing: 3px;\n  color: #fff;\n  font-family: firabold;\n  @media ", " {\n    font-size: 35px;\n  }\n"])), device.lg);
var OurCompanyText = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 24px;\n  color: #fff;\n  padding-top: 20px;\n  width: 88%;\n  line-height: 32px;\n  font-family: firareg;\n  @media ", " {\n    width: 100%;\n    font-size: 19px;\n    line-height: 24px;\n  }\n"], ["\n  font-size: 24px;\n  color: #fff;\n  padding-top: 20px;\n  width: 88%;\n  line-height: 32px;\n  font-family: firareg;\n  @media ", " {\n    width: 100%;\n    font-size: 19px;\n    line-height: 24px;\n  }\n"])), device.lg);
var Offers = styled(Wrapper)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: -75px;\n  display: flex;\n  justify-content: space-around;\n  align-items: stretch;\n  @media ", " {\n    justify-content: space-between;\n  }\n  @media ", " {\n    margin-top: 50px;\n    flex-wrap: wrap;\n  }\n"], ["\n  margin-top: -75px;\n  display: flex;\n  justify-content: space-around;\n  align-items: stretch;\n  @media ", " {\n    justify-content: space-between;\n  }\n  @media ", " {\n    margin-top: 50px;\n    flex-wrap: wrap;\n  }\n"])), device.xl, device.lg);
var OffersItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 24px;\n  color: #051f61;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  font-family: firabold;\n  @media ", " {\n    width: 50%;\n    margin-bottom: 50px;\n  }\n  @media ", " {\n    font-size: 18px;\n  }\n"], ["\n  font-size: 24px;\n  color: #051f61;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  font-family: firabold;\n  @media ", " {\n    width: 50%;\n    margin-bottom: 50px;\n  }\n  @media ", " {\n    font-size: 18px;\n  }\n"])), device.lg, device.md);
var OffersImg = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 150px;\n  height: 150px;\n  object-fit: contain;\n  margin-bottom: 10px;\n  @media ", " {\n    width: 100px;\n    height: 100px;\n  }\n"], ["\n  width: 150px;\n  height: 150px;\n  object-fit: contain;\n  margin-bottom: 10px;\n  @media ", " {\n    width: 100px;\n    height: 100px;\n  }\n"])), device.md);
var Informative = styled.main(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  background: url(", ") no-repeat bottom right;\n"], ["\n  width: 100%;\n  background: url(", ") no-repeat bottom right;\n"])), BgPart);
var Work = styled.section(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding-top: 290px;\n  display: flex;\n  align-items: flex-start;\n  @media ", " {\n    padding-top: 190px;\n  }\n  @media ", " {\n    padding-top: 50px;\n    flex-direction: column;\n  }\n"], ["\n  padding-top: 290px;\n  display: flex;\n  align-items: flex-start;\n  @media ", " {\n    padding-top: 190px;\n  }\n  @media ", " {\n    padding-top: 50px;\n    flex-direction: column;\n  }\n"])), device.xl, device.lg);
var WorkInfo = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var WorkTitle = styled.h1(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 48px;\n  color: #051f61;\n  margin-bottom: 40px;\n  font-family: firabold;\n  @media ", " {\n    width: 60%;\n    font-size: 35px;\n  }\n  @media ", " {\n    width: 100%;\n  }\n"], ["\n  font-size: 48px;\n  color: #051f61;\n  margin-bottom: 40px;\n  font-family: firabold;\n  @media ", " {\n    width: 60%;\n    font-size: 35px;\n  }\n  @media ", " {\n    width: 100%;\n  }\n"])), device.lg, device.md);
var WorkDesc = styled.p(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 18px;\n  font-family: firareg;\n  color: #17388a;\n  margin-bottom: 40px;\n  padding: 15px 0;\n  width: 88%;\n  margin: 0;\n  @media ", " {\n    width: 100%;\n  }\n  @media ", " {\n    width: 100%;\n    font-size: 19px;\n    line-height: 24px;\n  }\n"], ["\n  font-size: 18px;\n  font-family: firareg;\n  color: #17388a;\n  margin-bottom: 40px;\n  padding: 15px 0;\n  width: 88%;\n  margin: 0;\n  @media ", " {\n    width: 100%;\n  }\n  @media ", " {\n    width: 100%;\n    font-size: 19px;\n    line-height: 24px;\n  }\n"])), device.lg, device.lg);
var WorkDescYellow = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: #f2bf52;\n"], ["\n  color: #f2bf52;\n"])));
var WorkDescBlue = styled.span(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: #56bfc9;\n"], ["\n  color: #56bfc9;\n"])));
var WorkImg = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  margin-top: 40px;\n  padding: 0 20px;\n  .blue-trade {\n    animation: lavitate 3s linear infinite alternate;\n    @keyframes lavitate {\n      0% {\n        transform: translate(0, 0);\n      }\n      50% {\n        transform: translate(0, -20px);\n      }\n      100% {\n        transform: translate(0, 0);\n      }\n    }\n  }\n"], ["\n  width: 100%;\n  height: auto;\n  margin-top: 40px;\n  padding: 0 20px;\n  .blue-trade {\n    animation: lavitate 3s linear infinite alternate;\n    @keyframes lavitate {\n      0% {\n        transform: translate(0, 0);\n      }\n      50% {\n        transform: translate(0, -20px);\n      }\n      100% {\n        transform: translate(0, 0);\n      }\n    }\n  }\n"])));
var About = styled.section(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 100%;\n  padding: 100px 0 50px 0;\n"], ["\n  width: 100%;\n  padding: 100px 0 50px 0;\n"])));
var AboutTitle = styled.h1(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-size: 42px;\n  color: #051f61;\n  margin-bottom: 35px;\n  font-family: firabold;\n  @media ", " {\n    font-size: 35px;\n  }\n"], ["\n  font-size: 42px;\n  color: #051f61;\n  margin-bottom: 35px;\n  font-family: firabold;\n  @media ", " {\n    font-size: 35px;\n  }\n"])), device.lg);
var AboutDesc = styled.p(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  font-size: 18px;\n  font-family: firareg;\n  color: #051f61;\n  margin: 0;\n  margin-bottom: 15px;\n  &:last-child {\n    margin-bottom: 0;\n  }\n  @media ", " {\n    width: 100%;\n    font-size: 19px;\n    line-height: 24px;\n  }\n"], ["\n  font-size: 18px;\n  font-family: firareg;\n  color: #051f61;\n  margin: 0;\n  margin-bottom: 15px;\n  &:last-child {\n    margin-bottom: 0;\n  }\n  @media ", " {\n    width: 100%;\n    font-size: 19px;\n    line-height: 24px;\n  }\n"])), device.lg);
var Partner = styled.section(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  padding: 90px 0;\n  display: flex;\n  @media ", " {\n    margin: 50px 0;\n    flex-direction: column;\n  }\n"], ["\n  padding: 90px 0;\n  display: flex;\n  @media ", " {\n    margin: 50px 0;\n    flex-direction: column;\n  }\n"])), device.lg);
var PartnerVisaul = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0 10px;\n"], ["\n  width: 100%;\n  padding: 0 10px;\n"])));
var PartnerDevise = styled.h2(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  font-size: 36px;\n  font-family: firareg;\n  color: #0b3ab3;\n  padding: 0 30px;\n  padding-top: 36px;\n  text-align: center;\n  @media ", " {\n    padding: 36px 10px 0 10px;\n  }\n"], ["\n  font-size: 36px;\n  font-family: firareg;\n  color: #0b3ab3;\n  padding: 0 30px;\n  padding-top: 36px;\n  text-align: center;\n  @media ", " {\n    padding: 36px 10px 0 10px;\n  }\n"])), device.xl);
var PartnerImg = styled.img(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  width: 88%;\n  height: auto;\n  @media ", " {\n    width: 100%;\n  }\n"], ["\n  width: 88%;\n  height: auto;\n  @media ", " {\n    width: 100%;\n  }\n"])), device.lg);
var PartnerInfo = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0 10px;\n"], ["\n  width: 100%;\n  padding: 0 10px;\n"])));
var PartnerTitle = styled.h1(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  margin-top: 112px;\n  font-size: 32px;\n  font-family: firareg;\n  color: #212529;\n  margin-bottom: 8px;\n  @media ", " {\n    font-size: 28px;\n  }\n"], ["\n  margin-top: 112px;\n  font-size: 32px;\n  font-family: firareg;\n  color: #212529;\n  margin-bottom: 8px;\n  @media ", " {\n    font-size: 28px;\n  }\n"])), device.lg);
var PartnerItem = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  font-size: 16px;\n  font-family: firareg;\n  color: #051f61;\n  padding: 10px 0;\n  padding-left: 30px;\n  position: relative;\n  &:after {\n    content: \"\";\n    width: 15px;\n    height: 15px;\n    background: #051f61;\n    border-radius: 50%;\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    left: 0;\n  }\n"], ["\n  font-size: 16px;\n  font-family: firareg;\n  color: #051f61;\n  padding: 10px 0;\n  padding-left: 30px;\n  position: relative;\n  &:after {\n    content: \"\";\n    width: 15px;\n    height: 15px;\n    background: #051f61;\n    border-radius: 50%;\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    left: 0;\n  }\n"])));
var Contact = styled.section(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  margin-bottom: 40px;\n  @media ", " {\n    flex-direction: column;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  margin-bottom: 40px;\n  @media ", " {\n    flex-direction: column;\n  }\n"])), device.lg);
var ContactTitle = styled.h1(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  font-size: 48px;\n  font-family: firabold;\n  color: #051f61;\n  @media ", " {\n    margin-bottom: 30px;\n  }\n  @media ", " {\n    font-size: 35px;\n  }\n"], ["\n  font-size: 48px;\n  font-family: firabold;\n  color: #051f61;\n  @media ", " {\n    margin-bottom: 30px;\n  }\n  @media ", " {\n    font-size: 35px;\n  }\n"])), device.lg, device.lg);
var ContactInfo = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var ContactItem = styled.a(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  display: block;\n  font-size: 24px;\n  text-align: right;\n  color: #0b3ab3;\n  margin-bottom: 16px;\n  font-family: firareg;\n  @media ", " {\n    font-size: 19px;\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  display: block;\n  font-size: 24px;\n  text-align: right;\n  color: #0b3ab3;\n  margin-bottom: 16px;\n  font-family: firareg;\n  @media ", " {\n    font-size: 19px;\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])), device.lg);
var MainPage = function () {
    return (React.createElement(Fragment, null,
        React.createElement(Header, null),
        React.createElement(OurCompany, null,
            React.createElement(Wrapper, null,
                React.createElement(OurCompanyTitle, null,
                    React.createElement(FormattedMessage, { id: "COMPANY_WHO" })),
                React.createElement(OurCompanyText, null,
                    React.createElement(FormattedMessage, { id: "COMPANY_WHO_DESC" })))),
        React.createElement(Informative, null,
            React.createElement(Offers, null,
                React.createElement(OffersItem, null,
                    React.createElement(OffersImg, { src: OffersIcon1 }),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "SERVICES" }))),
                React.createElement(OffersItem, null,
                    React.createElement(OffersImg, { src: OffersIcon2 }),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "CONSTRUCTION" }))),
                React.createElement(OffersItem, null,
                    React.createElement(OffersImg, { src: OffersIcon3 }),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "PRODUCTION" }))),
                React.createElement(OffersItem, null,
                    React.createElement(OffersImg, { src: OffersIcon4 }),
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "TRADE" })))),
            React.createElement(Wrapper, null,
                React.createElement(Work, { id: "how-to-work" },
                    React.createElement(WorkInfo, null,
                        React.createElement(WorkTitle, null,
                            React.createElement(FormattedMessage, { id: "HOW_WE_DO_TITLE" })),
                        React.createElement(WorkDesc, null,
                            React.createElement(FormattedMessage, { id: "HOW_WE_DO", values: {
                                    span: function () {
                                        var chunks = [];
                                        for (var _i = 0; _i < arguments.length; _i++) {
                                            chunks[_i] = arguments[_i];
                                        }
                                        return (React.createElement("span", { style: { color: "rgb(242, 191, 82)" } }, chunks));
                                    },
                                    span2: function () {
                                        var chunks = [];
                                        for (var _i = 0; _i < arguments.length; _i++) {
                                            chunks[_i] = arguments[_i];
                                        }
                                        return (React.createElement("span", { style: { color: "rgb(86, 191, 201)" } }, chunks));
                                    },
                                    tradeshift: function () {
                                        var chunks = [];
                                        for (var _i = 0; _i < arguments.length; _i++) {
                                            chunks[_i] = arguments[_i];
                                        }
                                        return (React.createElement("span", { style: { color: "rgb(86, 191, 201)" } }, chunks));
                                    },
                                    br: React.createElement("br", null)
                                } }))),
                    React.createElement(WorkImg, null,
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 333 250", fill: "none" },
                            React.createElement("g", { className: "blue-trade" },
                                React.createElement("path", { d: "M140.883 176.01C154.017 189.144 175.313 189.144 188.447 176.01C201.582 162.875 201.582 141.58 188.447 128.445C175.313 115.311 154.017 115.311 140.883 128.445C127.748 141.58 127.748 162.875 140.883 176.01Z", fill: "#078FE2" }),
                                React.createElement("path", { d: "M197.558 184.052C190.728 190.909 181.819 195.311 172.223 196.57C173.252 197.561 174.58 198.184 176.001 198.339L208.26 202.038C209.244 202.152 210.24 202.041 211.176 201.715C212.111 201.389 212.961 200.856 213.661 200.155C214.361 199.455 214.894 198.606 215.22 197.67C215.546 196.735 215.657 195.738 215.544 194.754L211.841 162.492C211.683 161.072 211.061 159.744 210.072 158.714C208.814 168.311 204.414 177.221 197.558 184.052Z", fill: "#078FE2" }),
                                React.createElement("path", { d: "M131.95 118.623C138.781 111.766 147.689 107.364 157.285 106.105C156.258 105.112 154.928 104.489 153.507 104.336L121.249 100.637C120.265 100.523 119.268 100.634 118.332 100.96C117.397 101.286 116.548 101.819 115.847 102.52C115.147 103.22 114.614 104.069 114.288 105.005C113.962 105.94 113.851 106.937 113.964 107.921L117.667 140.176C117.821 141.597 118.442 142.927 119.433 143.957C120.695 134.362 125.096 125.454 131.95 118.623Z", fill: "#078FE2" }),
                                React.createElement("path", { d: "M131.95 184.052C125.094 177.222 120.692 168.314 119.433 158.718C118.442 159.748 117.821 161.078 117.667 162.499L113.964 194.754C113.851 195.738 113.961 196.735 114.287 197.67C114.614 198.606 115.147 199.455 115.847 200.156C116.547 200.856 117.397 201.389 118.332 201.715C119.267 202.041 120.264 202.152 121.248 202.038L153.507 198.339C154.928 198.186 156.257 197.563 157.285 196.57C147.689 195.311 138.78 190.909 131.95 184.052Z", fill: "#078FE2" }),
                                React.createElement("path", { d: "M197.558 118.623C204.415 125.453 208.816 134.362 210.076 143.957C211.066 142.926 211.689 141.597 211.848 140.176L215.533 107.932C215.647 106.948 215.536 105.951 215.21 105.015C214.884 104.08 214.351 103.231 213.65 102.53C212.95 101.83 212.101 101.297 211.165 100.971C210.23 100.645 209.233 100.534 208.249 100.647L175.997 104.346C174.576 104.504 173.246 105.127 172.216 106.119C181.813 107.373 190.724 111.77 197.558 118.623Z", fill: "#078FE2" }),
                                React.createElement("path", { d: "M168.138 168.913H161.645V143.693H153.329V138.289H176.454V143.693H168.138V168.913Z", fill: "white" })),
                            React.createElement("path", { d: "M62.345 62.3415C55.5149 69.1982 46.6064 73.5999 37.0106 74.8591C38.0421 75.8493 39.3708 76.4724 40.7916 76.6321L73.0363 80.3169C74.0203 80.4302 75.0171 80.3197 75.9524 79.9936C76.8877 79.6676 77.7373 79.1345 78.4376 78.4341C79.138 77.7337 79.6711 76.8842 79.9972 75.9489C80.3232 75.0136 80.4337 74.0168 80.3205 73.0328L76.6214 40.7809C76.4638 39.3595 75.8405 38.0301 74.8484 37C73.5946 46.5965 69.1979 55.5075 62.345 62.3415Z", fill: "#F2BF52" }),
                            React.createElement("circle", { cx: "33.5145", cy: "33.5145", r: "33.5145", fill: "#F2BF52" }),
                            React.createElement("path", { d: "M40.2592 47.7246L38.2609 41.1642H28.2128L26.2145 47.7246H19.918L29.6456 20.05H36.7904L46.5557 47.7246H40.2592ZM36.8658 36.2627C35.0183 30.3181 33.9752 26.9561 33.7364 26.1769C33.5102 25.3977 33.3468 24.7819 33.2463 24.3294C32.8315 25.9381 31.6439 29.9159 29.6833 36.2627H36.8658Z", fill: "white" }),
                            React.createElement("path", { d: "M270.961 62.345C264.104 55.515 259.702 46.6064 258.443 37.0107C257.453 38.0421 256.83 39.3708 256.67 40.7916L252.985 73.0363C252.872 74.0203 252.983 75.0172 253.309 75.9525C253.635 76.8878 254.168 77.7373 254.868 78.4377C255.568 79.1381 256.418 79.6712 257.353 79.9972C258.289 80.3232 259.285 80.4337 260.269 80.3205L292.521 76.6214C293.943 76.4639 295.272 75.8405 296.302 74.8485C286.706 73.5946 277.795 69.1979 270.961 62.345Z", fill: "#56BFC9" }),
                            React.createElement("circle", { cx: "299.486", cy: "33.5145", r: "33.5145", fill: "#56BFC9" }),
                            React.createElement("path", { d: "M290.252 20.1632H298.83C302.739 20.1632 305.573 20.7224 307.332 21.841C309.104 22.947 309.99 24.7128 309.99 27.1384C309.99 28.7848 309.601 30.1358 308.822 31.1915C308.055 32.2472 307.031 32.8819 305.749 33.0956V33.2841C307.496 33.6737 308.752 34.4026 309.519 35.4709C310.298 36.5392 310.688 37.9594 310.688 39.7314C310.688 42.245 309.777 44.2056 307.954 45.6132C306.145 47.0208 303.681 47.7246 300.564 47.7246H290.252V20.1632ZM296.097 31.0784H299.49C301.073 31.0784 302.217 30.8333 302.921 30.3432C303.637 29.853 303.995 29.0424 303.995 27.9113C303.995 26.8556 303.606 26.1015 302.827 25.6491C302.06 25.1841 300.841 24.9515 299.169 24.9515H296.097V31.0784ZM296.097 35.716V42.8986H299.905C301.513 42.8986 302.701 42.5906 303.468 41.9748C304.234 41.359 304.618 40.4164 304.618 39.147C304.618 36.8597 302.984 35.716 299.716 35.716H296.097Z", fill: "white" })))),
                React.createElement(About, { id: "about-us" },
                    React.createElement(AboutTitle, null,
                        React.createElement(FormattedMessage, { id: "ABOUT_US" })),
                    React.createElement(AboutDesc, null,
                        React.createElement(FormattedMessage, { id: "ABOUT_PART1" })),
                    React.createElement(AboutDesc, null,
                        React.createElement(FormattedMessage, { id: "ABOUT_PART2" })),
                    React.createElement(AboutDesc, null,
                        React.createElement(FormattedMessage, { id: "ABOUT_PART3" })),
                    React.createElement(AboutDesc, null,
                        React.createElement(FormattedMessage, { id: "ABOUT_PART4" }))),
                React.createElement(Partner, { id: "partnership" },
                    React.createElement(PartnerVisaul, null,
                        React.createElement(PartnerImg, { src: partnerIcon }),
                        React.createElement(PartnerDevise, null,
                            React.createElement(FormattedMessage, { id: "DEVISE" }))),
                    React.createElement(PartnerInfo, null,
                        React.createElement(PartnerTitle, null,
                            React.createElement(FormattedMessage, { id: "PARTNER_TITLE" })),
                        React.createElement(PartnerItem, null,
                            React.createElement(FormattedMessage, { id: "PARTNER_ITEM1" })),
                        React.createElement(PartnerItem, null,
                            React.createElement(FormattedMessage, { id: "PARTNER_ITEM2" })),
                        React.createElement(PartnerItem, null,
                            React.createElement(FormattedMessage, { id: "PARTNER_ITEM3" })),
                        React.createElement(PartnerItem, null,
                            React.createElement(FormattedMessage, { id: "PARTNER_ITEM4" })),
                        React.createElement(PartnerItem, null,
                            React.createElement(FormattedMessage, { id: "PARTNER_ITEM5" })))),
                React.createElement(Contact, { id: "contact-us" },
                    React.createElement(ContactTitle, null,
                        React.createElement(FormattedMessage, { id: "CONTACT" })),
                    React.createElement(ContactInfo, null,
                        React.createElement(ContactItem, { href: "tel:+0322 19 44 94" }, "+0322 19 44 94"),
                        React.createElement(ContactItem, { href: "mailto:hello@tradeshift.ge" }, "hello@tradeshift.ge"),
                        React.createElement(ContactItem, null,
                            React.createElement(FormattedMessage, { id: "ADDRESS" }))))),
            React.createElement(Feedback, null))));
};
export default MainPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28;
