import React, { useEffect, useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
export var LanguageProvider = function (_a) {
    var children = _a.children;
    var _b = useState(new URLSearchParams(window.location.search).get("lang") || "ka"), language = _b[0], setLanguage = _b[1];
    useEffect(function () {
        var langParam = new URLSearchParams(window.location.search).get("lang");
        if (langParam && (langParam === "en" || langParam === "ka")) {
            setLanguage(langParam);
        }
    }, []);
    var updateLanguage = function (language) {
        setLanguage(language);
    };
    return (React.createElement(LanguageContext.Provider, { value: {
            language: language,
            updateLanguage: updateLanguage
        } }, children));
};
