export default {
    ADDRESS: "16 Besarion Zhgenti Str.",
    HOW_WORKING: "How We Work",
    ABOUT_US: "About Us",
    PARTNERS: "Our Partners",
    CONTACT: "Contact Us",
    FILL_STATEMENT: "Fill The Form",
    COMPANY_WHO: "For whom?",
    COMPANY_WHO_DESC: "We believe that entrepreneurs should focus on what they’re passionate about, rather than having to worry about how to finance their business. There are a lot of options out there, many of which are slow and challenging. That’s why we’ve created a better, faster way to help Georgian companies get funding.",
    SERVICES: "Service",
    CONSTRUCTION: "Construction",
    PRODUCTION: "Production",
    TRADE: "Trade",
    HOW_WE_DO_TITLE: "Let’s See How it Works",
    HOW_WE_DO: "There is a seller company <span>A</span> and a buyer company <span2>B</span2>.{br}{br} Company <span2>B</span2> wants to buy product/service from Company <span>A</span> with accounts payable, but the company <span>A</span> refuses.{br}{br}As mediators , we <tradeshift>(Tradeshift)</tradeshift> buy product/service from Company <span>A</span> and sell it with accounts payable to Company <span2>B</span2>.{br}{br}During agreement process with company <span>A</span>, we introduce our benefits and tell that at any time when company <span>A</span> needs to buy any product/service we can provide them with financial support. So company <span>A</span> can become company <span2>B</span2>.",
    ABOUT_PART1: "At Tradeshift, we believe that business growth can bring great global benefits. That is why, from the very first day, we aimed to make it easier for entrepreneurs to finance their business.",
    ABOUT_PART2: "Our smart and passionate team is distinguished by different skills and its members are not afraid to do what no one has dared to do so far.",
    ABOUT_PART3: "Tradeshift offers clients a wide range of services in one space, including daily, weekly or monthly trading.",
    ABOUT_PART4: "We believe that entrepreneurs should be obsessed with their favorite business and should not have to worry about where to find finances for their own business. The search process is often quite long and tedious, so we have invented a new, better and faster way to fund Georgian companies. With the help of our management system and team, which makes even the seemingly impossible possible, we help businesses to quickly and easily manage their cash flow.",
    DEVISE: "Tradeshift - “Shift your business to the right direction”",
    PARTNER_TITLE: "By Partnership with us",
    PARTNER_ITEM1: "You will not have a cash problem",
    PARTNER_ITEM2: "You will not have the feeling of fear and distrust",
    PARTNER_ITEM3: "You will not have your business interruption",
    PARTNER_ITEM4: "You will not lose new customers and extra income",
    PARTNER_ITEM5: "Save your time and energy",
    SEND: "Send",
    //REGISTRATION PAGE
    REG_ABOUT_PART1: "We believe that entrepreneurs should focus on what they’re passionate about, rather than having to worry about how to finance their business. There are a lot of options out there, many of which are slow and challenging. That’s why we’ve created a better, faster way to help Georgian companies get funding.",
    REG_ABOUT_PART2: "With our smart and passionate team, we help businesses take charge of their cash flow quickly and easily. It’s the combination of our management system and our people that makes the seemingly impossible possible.",
    REG_ABOUT_PART3: " ",
    REG_PARTNER: "BY PARTNERSHIP WITH US:",
    REG_PARTNER_PART1: "You will not have a cash problem;",
    REG_PARTNER_PART2: "You will not have the feeling of fear and distrust;",
    REG_PARTNER_PART3: "You will not have your business interruption;",
    REG_PARTNER_PART4: "You will not lose new customers and extra income;",
    REG_PARTNER_PART5: "Save your time and energy.",
    START_REGISTRATION: "REGISTER NOW",
    PRIVATE_INFO: "FILL IN PERSONAL DATA",
    NAME: "Name",
    SURNAME: "Surname",
    PRIVATE_NUMBER: "Personal Number",
    MOBILE_NUMBER: "Mobile number",
    EMAIL: "Email",
    LEGAL_ADDRESS: "Legal address",
    DOB: "Date of birth",
    GENDER: "Gender",
    JUR_OPTION: "IN THE CASE OF LEGAL ENTITY",
    JUR_NAME: "Legal name",
    COMPANY_ID: "ID Number",
    SPHERE_OF_ACTIVITY: "Field of activity",
    JOB_TITLE: "Your position",
    AGREE: "CONSENT",
    IS_CONSENT: "Consent to data processing",
    IDENTIFICATION: "IDENTIFICATION",
    RULE_TEXT_PART1: "To get an initial answer without coming to the branch, for personal data identification, please transfer 0.01 Gel on the following account:",
    RULE_TEXT_PART2: "After identification, our business consultant will contact you on the number provided in the application.",
    ACCOUNT_NUMBER: "ACCOUNT NUMBER",
    FORM_SEND: "SEND",
    //FEEDBACK
    NAME_AND_SURNAME: "Name and Surname",
    MESSAGE: "Your Message",
    REQUIRED: "Is required",
    EMAIL_FORMAT: "E-mail format is incorrect",
    SELECT: "Select",
    MALE: "Male",
    FEMALE: "Female"
};
