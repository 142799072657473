export var size = {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 320,
};
var device = {
    xl: "(max-width: " + size.xl + "px)",
    lg: "(max-width: " + size.lg + "px)",
    md: "(max-width: " + size.md + "px)",
    sm: "(max-width: " + size.sm + "px)",
    xsm: "(max-width: " + size.xs + "px)",
};
export default device;
