var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Wrapper from "./Wrapper";
import Logo from "./Logo";
import { FormattedMessage } from "react-intl";
import setting, { asset } from "../utils/setting";
import device from "./../config/device";
import { Link } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
var facebookIcon = asset("img/fb__lan.svg");
var enIcon = asset("img/en__lan.svg");
var facebookIconDark = asset("img/fb.svg");
var logoIconLight = asset("img/logo-white.png");
var logoIconDark = asset("img/logo-dark.png");
var menuIcon = asset("img/menu.svg");
var Menu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 30px;\n  height: 30px;\n  background: url(", ") no-repeat center;\n  background-size: contain;\n  display: none;\n  cursor: pointer;\n  @media ", " {\n    display: block;\n  }\n"], ["\n  width: 30px;\n  height: 30px;\n  background: url(", ") no-repeat center;\n  background-size: contain;\n  display: none;\n  cursor: pointer;\n  @media ", " {\n    display: block;\n  }\n"])), menuIcon, device.xl);
var WrapperExt = styled(Wrapper)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 2px solid rgba(255, 255, 255, 0.4);\n  background: ", ";\n  transition: 0.3s all;\n  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 10px;\n  @media ", " {\n    flex-wrap: wrap;\n    padding: 15px;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 2px solid rgba(255, 255, 255, 0.4);\n  background: ", ";\n  transition: 0.3s all;\n  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 10px;\n  @media ", " {\n    flex-wrap: wrap;\n    padding: 15px;\n  }\n"])), function (props) { return props.color || "#fff"; }, device.xl);
var HeaderStyle = styled.header(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 68px;\n  padding-left: 15px;\n  display: flex;\n  justify-content: center;\n  align-items: stretch;\n  margin-top: 15px;\n  z-index: 9999;\n  @media ", " {\n    flex-wrap: wrap;\n    height: auto;\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 68px;\n  padding-left: 15px;\n  display: flex;\n  justify-content: center;\n  align-items: stretch;\n  margin-top: 15px;\n  z-index: 9999;\n  @media ", " {\n    flex-wrap: wrap;\n    height: auto;\n  }\n"])), device.xl);
var SmallWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  @media ", " {\n    overflow: hidden;\n    transition: 0.3s all;\n    width: 100%;\n    flex-direction: column;\n    justify-content: space-between;\n    height: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  @media ", " {\n    overflow: hidden;\n    transition: 0.3s all;\n    width: 100%;\n    flex-direction: column;\n    justify-content: space-between;\n    height: ", ";\n  }\n"])), device.xl, function (props) { return (props.color ? props.color : "0px"); });
var NavBar = styled.nav(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  @media ", " {\n    flex-direction: column;\n    height: 100%;\n    justify-content: space-around;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  @media ", " {\n    flex-direction: column;\n    height: 100%;\n    justify-content: space-around;\n  }\n"])), device.xl);
var NavItem = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 0 14px;\n  font-size: 16px;\n  color: ", ";\n  font-family: firareg;\n  cursor: pointer;\n  text-align: center;\n  line-height: 1.2rem;\n  @media ", " {\n    margin-bottom: 15px;\n  }\n"], ["\n  padding: 0 14px;\n  font-size: 16px;\n  color: ", ";\n  font-family: firareg;\n  cursor: pointer;\n  text-align: center;\n  line-height: 1.2rem;\n  @media ", " {\n    margin-bottom: 15px;\n  }\n"])), function (props) { return props.color || "transparent"; }, device.xl);
var NavItemLink = styled(Link)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 0 14px;\n  font-size: 16px;\n  color: ", ";\n  font-family: firareg;\n  cursor: pointer;\n  text-align: center;\n  line-height: 1.2rem;\n  @media ", " {\n    margin-bottom: 15px;\n  }\n"], ["\n  padding: 0 14px;\n  font-size: 16px;\n  color: ", ";\n  font-family: firareg;\n  cursor: pointer;\n  text-align: center;\n  line-height: 1.2rem;\n  @media ", " {\n    margin-bottom: 15px;\n  }\n"])), function (props) { return props.color || "transparent"; }, device.xl);
var SocialTag = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 0 10px;\n  width: 25px;\n  height: 25px;\n"], ["\n  margin: 0 10px;\n  width: 25px;\n  height: 25px;\n"])));
var FbIcon = styled(SocialTag)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: url(", ") no-repeat center;\n  background: ", ";\n  background-size: contain;\n"], ["\n  background: url(", ") no-repeat center;\n  background: ", ";\n  background-size: contain;\n"])), facebookIcon, function (props) { return props.color || "url(" + facebookIcon + ")no-repeat center"; });
var LangIcon = styled(SocialTag)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: url(", ") no-repeat center;\n  background: ", ";\n  background-size: 100% 100%;\n"], ["\n  background: url(", ") no-repeat center;\n  background: ", ";\n  background-size: 100% 100%;\n"])), enIcon, function (props) { return props.color || "url(" + enIcon + ")no-repeat center"; });
var LangChange = styled.button(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 25px;\n  height: 25px;\n  border: 2px solid ", ";\n  border-radius: 50%;\n  color: ", ";\n  font-size: 10px;\n  line-height: 21px;\n  text-align: center;\n  background: transparent;\n  outline: none !important;\n  box-shadow: none !important;\n  font-family: arial;\n  padding: 0;\n"], ["\n  width: 25px;\n  height: 25px;\n  border: 2px solid ", ";\n  border-radius: 50%;\n  color: ", ";\n  font-size: 10px;\n  line-height: 21px;\n  text-align: center;\n  background: transparent;\n  outline: none !important;\n  box-shadow: none !important;\n  font-family: arial;\n  padding: 0;\n"])), function (props) { return props.color || "#fff"; }, function (props) { return props.color || "#fff"; });
var SocialTagWrp = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Header = function () {
    var _a = useState(true), isTop = _a[0], setIsTop = _a[1];
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useContext(LanguageContext), language = _c.language, updateLanguage = _c.updateLanguage;
    var scrollHeader = function () { return setIsTop(window.scrollY < 1); };
    var menuToggleHandler = function () {
        setIsTop(false);
        setIsOpen(!isOpen);
    };
    var closeMenuHandler = function () {
        setIsOpen(false);
    };
    useEffect(function () {
        document.addEventListener("scroll", scrollHeader);
        return function () {
            document.removeEventListener("scroll", scrollHeader);
        };
    }, []);
    return (React.createElement(HeaderStyle, null,
        React.createElement(WrapperExt, { color: isTop ? "transparent" : " #fff" },
            React.createElement(Link, { to: "/" },
                React.createElement(Logo, { icon: isTop ? logoIconLight : logoIconDark, onClick: function () { return window.scrollTo(0, 0); } })),
            React.createElement(Menu, { onClick: menuToggleHandler }),
            React.createElement(SmallWrapper, { color: isOpen ? "200px" : "0px" },
                React.createElement(NavBar, null,
                    React.createElement(NavItem, { onClick: closeMenuHandler, href: "#how-to-work", color: isTop ? "#fff" : "rgb(7, 134, 226)" },
                        React.createElement(FormattedMessage, { id: "HOW_WORKING" })),
                    React.createElement(NavItem, { onClick: closeMenuHandler, href: "#about-us", color: isTop ? "#fff" : "rgb(7, 134, 226)" },
                        React.createElement(FormattedMessage, { id: "ABOUT_US" })),
                    React.createElement(NavItem, { onClick: closeMenuHandler, href: "#partnership", color: isTop ? "#fff" : "rgb(7, 134, 226)" },
                        React.createElement(FormattedMessage, { id: "PARTNERS" })),
                    React.createElement(NavItem, { onClick: closeMenuHandler, href: "#contact-us", color: isTop ? "#fff" : "rgb(7, 134, 226)" },
                        React.createElement(FormattedMessage, { id: "CONTACT" })),
                    React.createElement(NavItemLink, { to: "/register", color: isTop ? "#fff" : "rgb(7, 134, 226)" },
                        React.createElement(FormattedMessage, { id: "FILL_STATEMENT" }))),
                React.createElement(SocialTagWrp, null,
                    React.createElement(LangChange, { color: isTop ? "#fff" : "#0786E2", onClick: function () {
                            updateLanguage(language === "en" ? "ka" : "en");
                        } }, language === "ka" ? "EN" : "KA"),
                    React.createElement(FbIcon, { href: setting("app__fb_url"), target: "_blank", color: isTop
                            ? "url(" + facebookIcon + ") no-repeat center"
                            : "url(" + facebookIconDark + ") no-repeat center" }))))));
};
export default Header;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
