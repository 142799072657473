import { useEffect } from "react";
import { useHistory } from "react-router-dom";
var ScrollToTop = function () {
    var history = useHistory();
    useEffect(function () {
        return history.listen(function () {
            window.scrollTo(0, 0);
        });
    }, []);
    return null;
};
export default ScrollToTop;
